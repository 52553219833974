<template>
  <div id="solution">
    <NavBar :message="status"></NavBar>
    <header>
      <p class="header_p1">阅图智能</p>
      <p class="header_p2">阅见世界 图观未来</p>
    </header>
    <main>
      <div id="main-nav" :class="{ is_fixed: isFixed }">
        <ul class="ul">
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="handleClick(index, item.id)"
          >
            <a href="javascript:void(10)">{{ item.name }}</a>
            <div :class="navActived == index ? 'blueLine' : ''"></div>
          </li>
        </ul>
      </div>
      <div id="vision">
        <div class="w1200">
          <div class="vision_top">
            <p class="p1">智慧远控解决方案</p>
            <p class="p2">
              作业过程中，利用远控视频画面及时发现作业过程中的安全隐患，如：吊具下行人、未拆锁堆箱、大车行走避障等；在远控作业时，通过视觉技术辅助司机完成对箱、箱型确认等操作。
            </p>
          </div>
          <div class="vision_content">
            <img :src="activeList[visionActived].img" alt="" />
          </div>
          <div
            class="vision_conten1"
            v-for="(item, index) in activeList"
            :key="index"
          >
            <img :src="item.img" alt="" />
            <p class="p1">{{ item.title }}</p>
            <p class="p2">{{ item.content }}</p>
          </div>
          <div class="vision_active">
            <div
              v-for="(item, index) in activeList"
              :key="index"
              class="active"
              :class="visionActived == index ? 'visionactive' : ''"
              @mouseenter="mouser(index)"
            >
              <p class="p1">{{ item.title }}</p>
              <p class="p2">{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="tally">
        <div class="tally_content">
          <div class="w1200">
            <div class="tally_title" id="tally_a1">
              <div class="grid_flex">
                <p class="p1">智能理货系统</p>
                <p class="p2">解决方案</p>
              </div>
              <div>
                <p class="p3">
                  进场道口、岸桥、轨道吊、轮胎吊等区域和设备使用智能理货系统，秒级获取箱号、箱型、封签、箱损、车牌号、集卡号等信息，识别率高达99%。
                </p>
              </div>
            </div>
            <div class="tally_main">
              <div class="main1">
                <div class="mainbox">
                  <img src="../../assets/images/solution_img1.png" alt="" />
                </div>
                <p class="main_p">箱号识别</p>
                <p class="main_p2">
                  不需要集装箱停留状态下，利用箱尾、侧箱、箱顶等融合信息，快速识别箱号信息，识别精度高达99%。
                </p>
              </div>
              <div class="main1">
                <div class="mainbox">
                  <img src="../../assets/images/solution_img2.png" alt="" />
                </div>
                <p class="main_p">集装箱验残</p>
                <p class="main_p2">
                  深度融合集装箱纹理及平面完整度信息，能够识别厘米级的残损状态。
                </p>
              </div>
            </div>
            <div class="tally_main" id="tally_a2">
              <div class="main1">
                <div class="mainbox">
                  <img src="../../assets/images/solution_img3.png" alt="" />
                </div>
                <p class="main_p">铅封检测</p>
                <p class="main_p2">采用自建数据集，识别多种箱型铅封状态。</p>
              </div>
              <div class="main1">
                <div class="mainbox">
                  <img src="../../assets/images/solution_img4.png" alt="" />
                </div>
                <p class="main_p">危险品标识检测</p>
                <p class="main_p2">
                  针对通用危险品标识和用户自定义标识进行检测，完善危险品集装箱进出管理。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="court">
        <div class="w1200">
          <div class="court_title">
            <div class="court_flex">
              <p class="p1">智慧场区</p>
              <p class="p2">解决方案</p>
            </div>
            <div>
              <p class="p3">
                可对场区道路自主进行功能区域划分，发现车辆、人员违法禁行，司机违规下车，安全违规行为等及时监管大屏联动报警。
              </p>
            </div>
          </div>
          <div class="court_wrap">
            <img src="../../assets/images/wrap1.png" alt="" />
            <p class="p1">人员安全管理</p>
            <p class="p2">
              对场区内自主定义的功能区域，进行安全规范识别，全时段监测未佩戴安全帽、违规下车等行为。
            </p>
          </div>
          <div class="court_wrap" style="margin-top: 28px">
            <img src="../../assets/images/wrap2.png" alt="" />
            <p class="p1">设备安全管理</p>
            <p class="p2">
              AI联合消防系统，AI联动AGV通道，AI联动集卡管理全面提升场区内设备安全系数。
            </p>
          </div>
          <div class="court_wrap court_wrap_bot" style="margin-top: 28px">
            <img src="../../assets/images/wrap3.png" alt="" />
            <p class="p1">生产安全管理</p>
            <p class="p2">
              场区生产管理系统深度融合，人员身份无感知查验，安全培训状态时刻掌控。
            </p>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <NavFooter></NavFooter>
    </footer>
    <Scroll></Scroll>
  </div>
</template>
<script>
import NavFooter from "../../components/Footer/footer.vue";
import Scroll from "../../components/scroll/scroll.vue";
import NavBar from "../../components/NavBar/navBar.vue";
export default {
  data() {
    return {
      status: "",
      navList: [
        {
          id: "#vision",
          name: "港口智能视觉解决方案",
        },
        {
          id: "#tally",
          name: "智能理货系统解决方案",
        },
        {
          id: "#court",
          name: "智慧场区解决方案	",
        },
      ],
      activeList: [
        {
          title: "作业人员保护",
          img: require("../../assets/images/solution_banner3.png"),
          content:
            "作业过程中，全程实时进行作业人员跟踪分析，安全态势感知，危险状况及时反馈给远控操作人员，防止事故发生。",
        },
        {
          title: "大车防撞",
          img: require("../../assets/images/solution_banner1.png"),
          content:
            "融合视觉、激光雷达等多维数据，在大车行走模式下，全方位保证安全。",
        },
        {
          title: "锁销状态监测",
          img: require("../../assets/images/solution_banner2.png"),
          content:
            "对作业拆装锁状态进行监测，发现异常情况，及时联动作业控制信号，避免发生集装箱倒塌事故",
        },
      ],
      visionActived: 0,
      navActived: 0,
      isFixed: false,
    };
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let offsetTop = document.querySelector("#main-nav").offsetTop;
      this.isFixed = scrollTop > offsetTop ? true : false;
      if (scrollTop < 600) {
        this.isFixed = false;
      }
      if (scrollTop < 700) {
        this.navActived = 0;
      }
      if (scrollTop > 1567) {
        this.navActived = 1;
      }
      if (scrollTop > 2933) {
        this.navActived = 2;
      }
    },
    handleClick(index, id) {
      setTimeout(() => {
        this.navActived = index;
      }, 300);
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    mouser(index) {
      this.visionActived = index;
    },
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
      }
    },
  },
  components: {
    NavBar,
    NavFooter,
    Scroll,
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    this.status = 1;
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    localStorage.setItem("toId", "");
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang='scss' scoped>
@import "./solution.scss";
</style>